.MuiPickersPopper-root {
    @apply mt-3 #{!important};
    .MuiPaper-root{
        @apply bg-eerieBlack rounded-xl;

        .MuiPickersDay-root, .MuiMenuItem-gutters, .MuiPickersCalendarHeader-label, .MuiDayCalendar-weekDayLabel, .MuiPickersYear-yearButton{
            @apply text-white hover:bg-primary;
        }

        .MuiPickersDay-root{
            &.Mui-selected{
                @apply bg-goGreen;
            }
        }

        .MuiDayCalendar-weekDayLabel{
            @apply text-gray;
        }

        .MuiSvgIcon-root {
            @apply text-white;
        }

        .MuiMenuItem-root, .MuiPickersYear-yearButton{
            &.Mui-selected{
                @apply bg-goGreen;
            }
        }

        .MuiDialogActions-root {
            .MuiButtonBase-root{
                @apply text-white bg-goGreen rounded-xl hover:bg-[#03874e] font-semibold mt-3;
            }
        }

        .MuiDigitalClock-root{
            @apply w-56;
        }
        
    }
}