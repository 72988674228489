.nav-item-wrapper{

    @apply min-w-[200px] py-3 px-4 bg-eerieBlack #{!important};

    &:hover{
        @apply bg-darkGrey #{!important};
    }

    &:not(:last-child){
        @apply border-b border-b-primary border-solid;
    }

    &.nav-item-mobile{
        border-bottom: 1px solid #E4E4E4;
    }

    .nav-item{
        @apply flex items-center text-white; 
    
        img, .mob-icon-mr{
            @apply mr-4;
        }

    }
}

.nav-img-width{
    max-width: 14rem;
}


.admin-nav-item-home{
    @apply text-white bg-primary font-sans font-medium text-base py-4 px-5 hover:bg-darckGray border-b border-solid border-darckGray #{!important};
    
    &:last-child{
        @apply border-b-0 #{!important};
    }
}

.admin-nav-item{
    @apply text-white font-sans font-medium text-base py-4 px-5 hover:bg-primary border-b border-solid border-primary border-0 bg-eerieBlack #{!important};

    &:last-child{
        @apply border-b-0 #{!important};
    }
}


.nav-icon-rotate{
    @apply rotate-180;
}