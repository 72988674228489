@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Rajdhani:wght@400;500;700&family=Source+Code+Pro:wght@300;400;500;600;700&family=Amatic+SC&display=swap');

html{
    @apply text-sm font-sans;

    @media (-webkit-device-pixel-ratio: 1.25) {
        @apply text-xs
    }

    @media screen and (max-width: 900px) {
        @apply text-xs
    }

}

.drop_link_at{
    @apply no-underline text-white;
}

*{
    @apply font-sans;
}

body{
    @apply font-sans bg-cover bg-no-repeat bg-center #{!important};
}

.router-link{
    @apply no-underline;
}

.drop_link{
    @apply underline text-white; 
}

#menu-appbar{
    .MuiPaper-root{
        @apply bg-eerieBlack rounded-xl;
    }
}

#waitModal{
    .MuiBackdrop-root{
        background-color: rgba(0, 0, 0, 0.8);
    }
}


#menu-, .MuiPopover-root.MuiMenu-root.MuiModal-root  {
    .MuiMenu-paper {
        @apply text-white bg-primary rounded-xl mt-3;
    }
}

.setup_rewards, .setup_criteria{
    #menu-, .MuiPopover-root.MuiMenu-root.MuiModal-root {
        .MuiMenu-paper {
            @apply bg-eerieBlack;
        }
    }
}

//scroll bar

::-webkit-scrollbar{
    right: 0;
    width: 6px;
    height: 8px;
}

::-webkit-scrollbar-thumb{
    background-color: #7f7f7f;
    border-radius: 6px;
    width: 6px;
    height: 8px;
}

::-webkit-scrollbar-track{
    background-color: transparent;
}
.userContent{
     div, span, p:last-child{
        @apply m-0 p-0;
    }

    p:not(:last-child){
        @apply m-0 mb-3 p-0;
    }
}    

.bg-gradient{
    background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.2) 20%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 70%, rgba(0,0,0,0.2) 80%, rgba(0,0,0,0.7) 100%);
}

.linkAccountBtn{
    &.Mui-disabled{
        @apply text-gray border-gray #{!important};
    }
}


//Hide Input Number Arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.drag-drop{
    & > div {
        @apply rounded-2xl;
    }
}

//swiper js

.swiper-button-next{
    background-image: url('../assets/arrow-next.svg');
    background-size: cover;
    background-repeat: no-repeat;
    right: 0.75rem !important;
    width: 3.75rem !important;
    height: 3.75rem !important;

    &::after{
        content:'' !important;
    }
}

.swiper-button-prev{
    background-image: url('../assets/arrow-back.svg');
    background-size: cover;
    background-repeat: no-repeat;
    left: 0.75rem !important;
    width: 3.75rem !important;
    height: 3.75rem !important;


    &::after{
        content:'' !important;
    }
}

.insta-btn{
    @apply bg-[#039503] py-[1.5rem] px-[3rem] font-sans text-2xl font-semibold min-h-[3rem] min-w-[7.5rem] text-white rounded-[2.5rem] uppercase border-0 cursor-pointer hover:bg-opacity-75;
}