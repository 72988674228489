.modal-base{
   @apply absolute top-1/2 left-1/2 translate-y-1/2M translate-x-1/2M w-full max-w-[47.25rem] p-3 outline-0;

   &.modal-success {
      @apply max-w-[28.75rem] outline-0;

      .content-wrapper{
         @apply p-5 #{!important};

      }
   }

   &.modal-login{
      @apply max-w-[31.25rem] outline-0;

      .content-wrapper{
         @apply p-10 #{!important};

         .modal-title{
            @apply pt-7 mb-4;
         }
      }
   }

      .icon-wrapper{
         @apply h-[15.625rem] text-center;

         .icon-img{
            @apply w-[15.625rem] text-center;
         }
      }

}

.modal-container{
    @apply bg-darkGrey w-full rounded-3xl drop-shadow-modal border-2 border-[#1A1C1E] border-solid;


     .modal-Header1{
        @apply pb-5 text-white text-3xl font-semibold;
     }

     .modal-text24{
         @apply mb-4 text-white;
     }

     .modal-text18{
         @apply px-3 mb-10 text-white;
     }

     .modal-button-wrapper{
         button{
            @apply w-full mb-4;

            &:last-child{
               @apply mb-0; 
            }
         }

         &.modal-button-wrapper-flex{
            @apply flex justify-between;

            button{
               @apply w-[48%] mb-0;
   
            }
         }
     }

     .content-wrapper{
        @apply p-8 pt-0 sm:p-16 pb-16 sm:p-20 sm:pt-0 #{!important};

        .content-warning{
         @apply flex items-center h-[12.625rem] justify-center ;

            .icon-warning{
               @apply w-[7rem] #{!important};
            }

        }


        .modal-text{
            @apply leading-7 px-10  text-white text-2xl font-normal;
        }
        
     }

}

.modal-mb-4{
   @apply mb-16 #{!important};
}